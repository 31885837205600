

import React from "react";

export function currentLang(){
  var page_lang = String( document.documentElement.lang || 'pl' ).toLowerCase();
  return page_lang;
}
export default function T(props){
    var page_lang = currentLang();
    if(page_lang === 'cs'){
        page_lang = 'cz';
    }
    
    if(props[page_lang]){
        return <span dangerouslySetInnerHTML={{__html: props[page_lang]}} />
    }
    return <span dangerouslySetInnerHTML={{__html: props['pl']}} />
}
export  function _t(texts = {}){
    var page_lang = currentLang();
    if(page_lang === 'cs'){
        page_lang = 'cz';
    }
    
    if(texts[page_lang]){
        return texts[page_lang];
    }
    return texts['pl'];
}
