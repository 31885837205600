import React from "react";
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip'
import T from "../T";

const Head = styled.div`
  display: flex;
  @media screen and (max-width: 999px){
    flex-wrap: wrap;
  }
}
`;
const ModalTitle = styled.h4`
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 1em;
  @media screen and (max-width: 999px){
    margin-top: 0;
  }
}
`;
const TopHead = styled.p`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
font-weight: 300;
font-size: 13px;
line-height: 20px;
color: #555555;
width: 400px;
max-width: 100%;
@media screen and (max-width: 999px){
  br{
    display: none;
  }
}
}
`;
const HeadWrapper = styled.div`
  @media screen and (max-width: 999px){
    margin-top: 0;
  }
}
`;
const TopImage = styled.div`
  width: 404px;
  height: 284px;
  background: url("https://ar.scandicsofa.pl/probnik/p2.jpg") top right no-repeat;
  background-size: cover;
  max-width: 100%;
  @media screen and (max-width: 999px){
    background-size: contain;
    background-position: center;
  }
}
`;

const ModalHead = () => {

    return (
        <Head>
            <HeadWrapper>
                <ModalTitle>
                  <T 
                  pl={`Darmowy próbnik`}
                  cz={`VZORNÍK ZDARMA`} 
                  lt={'NEMOKAMAS PAVYZDYS'}
                  hu={'INGYENES MINTADARAB'}
                  />
                </ModalTitle>
                <ReactTooltip place="top" type="dark" effect="solid"/>
                <TopHead>
                  <T 
                  pl={`Współpracujemy z renomowanymi producentami tkanin z Włoch, Belgii oraz Danii. Wieloletnie doświadczenie naszych partnerów przekłada się wysoką jakość i trwałość produkowanych materiałów.
<br><br>
                  W naszej ofercie znajdziesz zarówno tkaniny wykonane z włókien naturalnych takich jak len i bawełna jak i wysokogatunkowe tkaniny syntetyczne typu EasyClean. Są one bardzo łatwe w czyszczeniu- polecamy je szczególnie rodzinom z dziećmi i właścicielom czworonogów. `}
                  cz={`Spolupracujeme s renomovanými výrobci látek z Itálie, Belgie a Dánska. Mnohaleté zkušenosti našich partnerů se promítají do vysoké kvality a trvanlivosti vyráběných materiálů. V naší nabídce jsou především látky vyráběné z přírodních vláken, jako je len a bavlna. Na speciální přání Zákazníka můžeme vyrobit nábytek z kvalitních syntetických látek typu EasyClean, které se velmi snadno čistí.
                  Překrásné barvy a bohatá paleta odstínů uspokojí různé vkusy našich Zákazníků. Tlumené barvy doplňují kompozici jemných a teplých interiérů. Intenzivní barvy doporučujeme Zákazníkům, kteří se nebojí zajímavých řešení a originálních návrhů.`} 
                  lt="Dirbame tik su žinomais audinių gamintojais iš Italijos, Belgijos ir Danijos.  Ilgametė mūsų partnerių patirtis lemia aukštą gaminamų medžiagų kokybę ir ilgaamžiškumą. Mūsų asortimentą sudaro audiniai iš natūralaus pluošto, pavyzdžiui, lino ir medvilnės, taip pat aukštos kokybės sintetiniai EasyClean audiniai. Juos labai lengva valyti - ypač rekomenduojame šeimoms su vaikais ir keturkojų šeimininkams."
                  hu={`Neves olasz, belga és dán szövetgyártókkal dolgozunk együtt. Partnereink sokéves tapasztalata
                  biztosítja a gyártott szövetek magas minőségét és tartósságát.<br><br>
                  Kínálatunkban természetes anyagokból, például lenből és pamutból készült szövetek, valamint kiváló
                  minőségű szintetikus EasyClean szövetek is megtalálhatóak. Ezek nagyon könnyen tisztíthatók –
                  különösen ajánljuk őket gyermekes családok és állattartók részére.`}
                  />
                  </TopHead>
                </HeadWrapper>
            <TopImage/>
        </Head>
    )
}
export { ModalHead }
