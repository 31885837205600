import React, { useState } from "react";
import { Popup } from "./components/PopUp/Popup";
import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  text-align: center;
  padding: 10px 0;
  font-family: "Poppins", sans-serif;
`;
const ButtonHref = styled.a`
  clear: both;
  width: 100%;
  color: #303030;
  text-align: center;
  padding: 8px 0 8px 0;
  display: block;
  font-weight: 400;
  margin-bottom: 30px;
  background-color: #dedede;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.7, 0.39, 0.55, 0.95),
    background-color 0.3s cubic-bezier(0.7, 0.39, 0.55, 0.95);
  &:hover {
    background-color: #303030;
    color: #fff;
  }
`;

export const MAX_ITEMS = 8;

function App({ children }) {
  const openModal = function (e) {
    if (e != null) {
      e.preventDefault();
    }
    window.openModal = false;
    setShow(true);
    return false;
  };
  const [show, setShow] = useState(false);
  window.jQuery(document).on("click", ".tkanina", openModal);

  window.jQuery(function () {
    if (window.openModal == true) {
      openModal();
    }
  });
  return (
    <Wrapper>
      {children}
      <Popup
        title="DARMOWY PRÓBNIK"
        onClose={() => setShow(false)}
        show={show}
      />
    </Wrapper>
  );
}

export default App;
