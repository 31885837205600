import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AccordionWrapper = styled.div`
 border-top: 1px solid #E0E0E0;
 padding: 5px 10px
}
`;
const ButtonAccordion = styled.div`
  cursor: pointer;
  padding: 10px 0;
}
`;
const AccordionBody = styled.div`
     margin-top: 10px;
     padding: 10px 0;
}
`;
const Chevron = styled.div`
  float: right;
}
`;
const SVG = styled.svg`
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform .3s cubic-bezier(0.7, 0.39, 0.55, 0.95);
  &.true{
   transform: rotate(180deg);
    transition: transform .3s cubic-bezier(0.7, 0.39, 0.55, 0.95);
  }
`;
const ButtonAccordionLabel = styled.span`
  font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 111%;
color: #555555;
`;

class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.any.isRequired,
        onClick: PropTypes.func.isRequired,
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const {
            onClick,
            props: { isOpen, label, hidden },
        } = this;

        if(hidden){
          return null;
        }

        return (
            <AccordionWrapper>
                <ButtonAccordion onClick={onClick}>
                    <ButtonAccordionLabel>{label}</ButtonAccordionLabel>
                    <Chevron>
                        {!isOpen && <SVG width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29 1L14.9691 16L1 1" stroke="#727373" strokeMiterlimit="10"/>
</SVG>
}
                        {isOpen && <SVG className={isOpen} width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29 1L14.9691 16L1 1" stroke="#727373" strokeMiterlimit="10"/>
                        </SVG>
                        }
                    </Chevron>
                </ButtonAccordion>
                {isOpen && (
                    <AccordionBody>
                        {this.props.children}
                    </AccordionBody>
                )}
            </AccordionWrapper>
        );
    }
}

export  { AccordionSection };
