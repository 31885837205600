import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AccordionSection } from './AccordionSection';

const BodyAccordion = styled.div`
  margin-top: 30px;
  margin-right: 65px;
  @media screen and (max-width: 999px){
    margin-right: 0;
  }
`;

class Accordion extends Component {
    static propTypes = {
        allowMultipleOpen: PropTypes.bool,
        children: PropTypes.instanceOf(Object).isRequired,
    };

    constructor(props) {
        super(props);

        const openSections = {};

        this.props.children.forEach(child => {
            if (child.props.isOpen) {
                openSections[child.props.label] = true;
            }
        });

        this.state = { openSections };
    }

    onClick = label => {
        const {
            props: { allowMultipleOpen },
            state: { openSections },
        } = this;

        const isOpen = !!openSections[label];

        if (allowMultipleOpen) {
            this.setState({
                openSections: {
                    ...openSections,
                    [label]: !isOpen
                }
            });
        } else {
            this.setState({
                openSections: {
                    [label]: !isOpen
                }
            });
        }
    };

    render() {
        const {
            onClick,
            props: { children },
            state: { openSections },
        } = this;

        return (
            <BodyAccordion>
                {children.map((child, i) => (
                    <AccordionSection
                        isOpen={!!openSections[child.props.label]}
                        label={child.props.label}
                        hidden={child.props.hidden}
                        onClick={onClick}
                        key={i}
                    >
                        {child.props.children}
                    </AccordionSection>
                ))}
            </BodyAccordion>
        );
    }
}

export { Accordion };
