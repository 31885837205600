import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import styled from 'styled-components';
import { ModalBody } from "../ModalBody/ModalBody";

const PopupWindow = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    max-height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    z-index: 1111;
    &.enter-done {
      opacity: 1;
      background-color: rgba(255,255,255, .6);
      pointer-events: visible;
      }
      &.exit {
        opacity: 0;
      }
    }
    @media screen and (max-width: 999px){
        padding-top: 0;
        padding-bottom: 0;
    }
`;
const PopupContent = styled.div`
    width: 900px;
    background-color: #fff;
    transition: all .3s cubic-bezier(0.7, 0.39, 0.55, 0.95);
    box-shadow: 0 0.1875rem 0.375rem rgba(0,0,0,.16);
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0,0,0,.2);
    outline: 0;
    opacity: 1;
    z-index: 10001;
    overflow: auto;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    @media screen and (max-width: 999px){
        div>div{
            display: block;
            margin-left: 0px !important;
            div:last-child{
                margin-bottom: 20px;
            }
        }
    }
}
`;
const ModalHeader = styled.div`
  padding: 10px;
}
`;
const Body = styled.div`
  padding: 10px;
  border-bottom: 1px solid #eee;
  min-height: 500px;
}
`;
const ModalFooter = styled.div`
   padding: 10px;
}
`;
const Button = styled.button`
   position: absolute;
   right: 20px;
   top: 20px;
   width: 15px;
   height: 15px;
   border: none;
   outline: none;
   margin: 0;
   padding: 0;
   cursor: pointer;
   background: none;
   transform: rotate(0deg);
   transition: transform .2s cubic-bezier(0.7, 0.39, 0.55, 0.95);
   &:hover{
   transform: rotate(180deg);
}
`;

const Popup = props => {
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    function onscroll(){

        console.log('scroll');
            // hide popovers on scroll
        // document.addEventListener('scroll', () => {
            for (const popper of document.querySelectorAll('[data-tippy-root]')) {
                const instance = popper._tippy;

                if (instance.state.isVisible) {
                instance.hide();
                }
            }
        // });
    }

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    });

    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
            onScroll={onscroll}
        >
            <PopupWindow 
             onScroll={onscroll}
             onClick={props.onClose}>
                <PopupContent onClick={e => e.stopPropagation()}>
                    <ModalHeader>
                        <Button onClick={props.onClose}>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="0.646447" y1="14.6464" x2="14.6464" y2="0.646447" stroke="black"/>
                                <line x1="0.353553" y1="0.646447" x2="14.3536" y2="14.6464" stroke="black"/>
                            </svg>
                        </Button>
                    </ModalHeader>
                    <Body><ModalBody onClose={props.onClose}/></Body>
                    <ModalFooter />
                </PopupContent>
            </PopupWindow>
        </CSSTransition>,
        document.getElementsByClassName('material_picker')[0]
    );
};

export  { Popup }
