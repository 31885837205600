
import React from "react";
import styled from 'styled-components';

import T, { _t } from "./T";

const WarningWrapper = styled.div`
display: block;
font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 129%;
color: #555555;
margin-bottom:1em;

`;

export function Warning() {

  return <WarningWrapper>

    <T
      pl="Uwaga! Producent tkaniny dopuszcza występowanie różnic odcieni pomiędzy poszczególnymi partiami produkcyjnymi."
      cz="Upozorňujeme na rozdíly v odstínech mezi jednotlivými výrobními šaržemi."
      lt="Dėmesio! Audinių gamintojas įspėja, kad tarp atskirų audinių gamybos partijų gali atsirasti atspalvių skirtumai."
      hu="Megjegyzés: A szövetgyártó fenntartja annak a lehetőségét, hogy a színárnyalatokban a gyártás során eltérések keletkezhetnek."
    />
  </WarningWrapper>
}
