import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  /* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700&display=swap');
  body {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    color: #555555;
    overflow: auto;
  }
  h1, h2, h3, h4, h5, h6{
  font-family: 'Poppins', sans-serif;
  }
  .custom__picker{
  display: block;
  float: none;
  
  } */
  [data-tippy-root]{
    z-index:100000 !important;
  }

 
`
export  { GlobalStyle }
