import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css';
import { MAX_ITEMS } from '../../App';

const ItemMain = styled.div`
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-collapse: collapse;
  width: 100px;
  min-width: 100px;
  height: 120px;
  cursor: pointer;
  &.clicked_btn{
    background-color: #F1F1F1;
    position: relative;
    &::before{
      background-image: url('https://ar.scandicsofa.pl/probnik/check.svg');
      background-repeat: no-repeat;
      content: '';
      position: absolute;
      right: 18%;
      top: 8%;
      background-size: contain;
      height: 19px;
      width: 19px;
      z-index: 10;
    }
  }
  @media screen and (min-width: 1000px){
    &:hover{
      background-color: #F1F1F1;
    }
  }
  @media screen and (max-width: 999px){
    width: 80px;
    min-width: 80px;
  }
`;
const ImageWrapper = styled.div`
  margin: 5px auto;
  text-align: center;
  display: block;
`;
const Image = styled.img`
  margin: 15px auto 5px auto;
  text-align: center;
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: contain;
  position: relative;
`;
const ItemDescription = styled.p`
  font-family: 'Poppins', sans-serif;
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 127%;
text-align: center;
padding-top: 5px;
color: #828282;
`;

const handleClick = (text, type, e) => {
  e.preventDefault();
  
  const isActive = e.currentTarget.classList.contains('clicked_btn');
  
  let cart = JSON.parse(localStorage.getItem('products-in-cart'));

  if(cart == null){
    cart = [];
  }
  if(isActive){
    cart = cart.filter(e => e !== (type+' | '+text))
    e.currentTarget.classList.remove('clicked_btn');
  }else{
    if(cart.length < MAX_ITEMS){
      cart.push(type+' | '+text);
      e.currentTarget.classList.add('clicked_btn');
    }
  }
  
  
  document.getElementById('selectedCount').innerHTML = cart.length;
  console.log(cart);
	localStorage.setItem(
		'products-in-cart',
		JSON.stringify(cart)
	);
}

const Item = (props) => {
    const {full, text, type} = props
    let cart = JSON.parse(localStorage.getItem('products-in-cart'));
    const width = window.innerWidth < 800 ? '150px' : '230px';
    const isActive = cart !==null?cart.includes(type+' | '+text):false;
    return(
      <Tippy theme={'light'} style={{zIndex:10100}} content={<img style={{maxWidth: width, display:'block', borderRadius:'3px', margin:'3px 0'}} src={full}/>}>
        <ItemMain data-i={type} onClick={handleClick.bind(this, text, type)} className={(isActive ? 'clicked_btn':'')}>
          <ImageWrapper>
              <Image src={full} full={full} alt={text}/>
          </ImageWrapper>
          <ItemDescription>
              {text}
          </ItemDescription>
        </ItemMain>
      </Tippy>
    )
}

Item.propTypes = {
    full: PropTypes.string,
    text: PropTypes.string
}

export  {Item}
