import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalStyle } from './GlobalStyle'

ReactDOM.render(
    <App>
        <GlobalStyle/>
    </App>,
  document.getElementsByClassName('material_picker')[0]
);

function gup( name, url ) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
  var regexS = "[\\?&]"+name+"=([^&#]*)";
  var regex = new RegExp( regexS );
  var results = regex.exec( url );
  return results == null ? null : results[1];
}
  if(gup('picker') == 1){
    console.log('test');
    window.openModal = true;
  }