import React from "react";
import styled from 'styled-components';
import {ModalHead} from "./ModalHead";
import {Accordion} from "../Accordion/Accordion";
import {Item} from "../Item/Item";
import T, { currentLang, _t } from "../T";
import { Warning } from "../Warning";
import { MAX_ITEMS } from "../../App";


const ASSETS_DIR = !window.location.href.match(/localhost/i) ? 'https://ar.scandicsofa.pl/probnik/' : '/probnik/';

const Body = styled.div`
   display: block;
   margin-left: 65px;
}
`;
const HeaderBody = styled.div`
    display: flex;
    margin-right: 65px;
    margin-top: 65px;
    @media screen and (max-width: 999px){
        margin-top: 0px;
        margin-right: 0;
    }
}
`;
const Left = styled.div`
    order: 1;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 137%;
    color: #555555;
    width: 50%;
    @media screen and (max-width: 999px){
        width: 100%;
    }
}
`;
const Right = styled.div`
    order: 2;
    width: 50%;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 137%;
    color: #555555;
    padding-top: 20px;
    @media screen and (max-width: 999px){
        width: 100%;
    }
}
`;
const ModalFooter = styled.div`
  display: block;
  margin: 30px auto;
  text-align: center;
}
`;
const FooterButton = styled.button`
  text-align: center;
  margin: 7px auto;
  
  box-sizing: border-box;
  padding: 15px 60px;
  background-color: unset;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129%;
  width: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  border: 1px solid #B2B1B0;
  background: #fff;
  cursor: pointer;
  flex-grow: 1;
  transition: color 0.3s cubic-bezier(0.7, 0.39, 0.55, 0.95) 0s, background-color 0.3s cubic-bezier(0.7, 0.39, 0.55, 0.95) 0s, border 0.3s cubic-bezier(0.7, 0.39, 0.55, 0.95) 0s;
  
  border-radius: 50px;

  &:hover{

    background-color: #eee;
    color: #000;
  }
  &.exit{
      @media screen and (min-width: 1000px){
          display: none;
      }
  }
`;
const Features = styled.div`
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 143%;
  color: #555555;
}
`;
const Description = styled.div`
  font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 143%;
    color: #555555;
    margin-bottom:1em;
    a{
      color: inherit;
      text-decoration: underline;
    }
`;
const FeaturesLeft = styled.div`
  min-width: 200px;
  text-align: left;
`;
const FeatureRight = styled.div`
  text-align: left;
`;
const AccordionMain = styled.div`
  text-align: left;
  display: ${props => props.hidden ? 'none' : 'block'};
`;
const ItemsWrapper= styled.div`
  display: flex !important;
  flex-wrap: wrap; 
  padding: 30px 0;
  overflow: hidden;
  @media screen and (max-width: 999px){
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
  
`;



const ModalBody = (props) => {
    let selected = 0;
    const cart = JSON.parse(localStorage.getItem('products-in-cart'));

    if(cart !== null){
       selected = cart.length;
    }
    function confirmStatement() {
        // stock_id: 4907, options: {
        //         34
        const cart = JSON.parse(localStorage.getItem('products-in-cart'));

        if(cart.length > 0){


          if(window['wc_add_to_cart_params']){
          // if(true){
            var f = new FormData();
            // wapf[field_656f703f8a478]: aaaaaa
            // wapf_field_groups: p_10544
            // quantity: 1
            // add-to-cart: 10544
            f.append('wapf[field_656f703f8a478]', cart.join('; '));
            f.append('wapf_field_groups', 'p_10544');
            f.append('quantity', 1);
            f.append('add-to-cart', 10544);
            fetch('/produkt/szovetmintat/', {
            // fetch('https://scandicsofa.hu/produkt/szovetmintat/', {
              method: 'POST',
              body: f,
            }).then(data => {
              console.log(data);
              console.log(data.text());
              debugger;
              window.location.href = window.wc_add_to_cart_params.cart_url;
            }).catch(err => {
              console.log(err);
              debugger;
            });
          }else{
          
            var options = {};
            var opId = _t({
                pl: 34,
                cz: 34,
                lt: 11,
                hu: 18,
            });
            options[opId] = cart.join('; ');
        
            // window.frontAPI.addToBasket(function (addInfo) {
            //     console.log(addInfo);
              
            // }, {
            //     data: {
            //         stock_id: 257,
            //         options: {18:'Material1, Material 2'}
            //     }
            // });
            window.frontAPI.addToBasket(function (addInfo) {
                console.log(addInfo);
                window.location.href = _t({
                    pl: "https://scandicsofa.pl/pl/basket",
                    cz: "https://scandicsofa.cz/cs_CZ/basket",
                    lt: "https://scandicsofa.lt/lt_LT/basket",
                    hu: "https://scandicsofa.hu/hu_HU/basket",
                });
            }, {
                data: {
                    stock_id: _t({
                        pl: 5084,
                        cz: 4907,
                        lt: 4229,
                        hu: 257,
                    }),
                    options: options
                }
            });
          }
        }
    }


    console.log({
      currentLang: currentLang()
    });
    
    return (
        <Body>
            <ModalHead />
            <HeaderBody>
                <Left>
                    <T 
                        pl={`Wybierz do ${MAX_ITEMS} tkanin, które dodamy do Twojego próbnika`}
                        cz={`Vyberte si maximálně ${MAX_ITEMS} látek`}
                        lt={`Pasirinkite iki ${MAX_ITEMS} audinių, kuriuos norite išbandyti`}
                        hu={`Válasszon legfeljebb ${MAX_ITEMS} féle ingyenes szövetmintát.`}
                    />
                    {/* <T lang="pl">
                        Wybierz maksymalnie 5 tkanin, <br/>które dodamy do Twojego próbnika:
                    </T>
                    <T lang="cz">
                        Vyberte si maximálně 5 látek:
                    </T> */}
                </Left>
                <Right>
                    <T 
                        pl="Wybrano" 
                        cz="Zvoleno"
                        lt="Pasirinkta"
                        hu="Kiválasztott"
                    />: <span id={'selectedCount'}>{selected}</span><span>/</span><span>{MAX_ITEMS}</span></Right>
            </HeaderBody>
            <Accordion>
                
                
                {/* ANGOLA */}
                <AccordionMain isOpen label={_t({ 
                    pl: "ANGOLA - TKANINA BOUCLE ŁATWOCZYSZCZĄCA" ,
                    cz: "ANGOLA - SNADNO ČISTITELNÁ LÁTKA",
                    lt: "ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI",
                    hu: "ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET",
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Klasyczna tkanina boucle wpisująca się w najnowsze wnętrzarskie trendy. Niezwykle miła w dotyku, gruba i mięsista. "
                           cz="Klasická buklé látka v souladu s nejnovějšími trendy interiérového designu. Mimořádně příjemná na dotek, hustá a plná."
                           lt="Klasikinis boucle audinys, atitinkantis naujausias interjero tendencijas. Itin malonus liesti, storas ir pūstas. "
                           hu="Klasszikus boucle szövet, amely megfelel a legújabb lakberendezési trendeknek. Rendkívül kellemes tapintású, vastag és bársonyos."
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="68% POLYAMIDE, 29% POLYESTER, 3% WISKOZA"
                           cz="68% POLYAMIDE, 29% POLYESTER, 3% WISKOZA"
                           lt="68% POLIAMIDAS, 29% POLIESTERIS, 3% VISKOZĖ"
                           hu="68% POLIAMID, 29% POLYESTER, 3% VISZKÓZ"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>50 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Cechy: "
                       cz="Vlastnosti:"
                       lt="Savybės:"
                       hu="Jellemzők:"
                       /></FeaturesLeft>
                        <FeatureRight><T
                           pl="łatwe czyszczenie wodą"
                           cz="Snadno se čistí vodou"
                           lt="lengva valyti vandeniu"
                           hu="Vízzel könnyen tisztítható"
                           /></FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime"
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától"
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 01'} full={ASSETS_DIR+'Angola/01.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 02'} full={ASSETS_DIR+'Angola/02.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 03'} full={ASSETS_DIR+'Angola/03.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 04'} full={ASSETS_DIR+'Angola/04.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 05'} full={ASSETS_DIR+'Angola/05.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 06'} full={ASSETS_DIR+'Angola/06.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 07'} full={ASSETS_DIR+'Angola/07.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 08'} full={ASSETS_DIR+'Angola/08.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 09'} full={ASSETS_DIR+'Angola/09.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 10'} full={ASSETS_DIR+'Angola/10.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 11'} full={ASSETS_DIR+'Angola/11.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 12'} full={ASSETS_DIR+'Angola/12.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 13'} full={ASSETS_DIR+'Angola/13.jpg'} />
                        <Item 
                            type={_t({
                                pl:'ANGOLA - TKANINA ŁATWOCZYSZCZĄCA', 
                                cz:'ANGOLA - SNADNO ČISTITELNÁ LÁTKA', 
                                lt:'ANGOLA - BOUCLE AUDINYS, KURĮ LENGVA VALYTI',
                                hu:'ANGOLA - KÖNNYEN TISZTÍTHATÓ BOUCLE SZÖVET',
                            })} 
                            text={'ANGOLA 14'} full={ASSETS_DIR+'Angola/14.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>
                
                {/* HIMALAYA */}
                <AccordionMain 
                label={_t({ 
                    pl: "HIMALAYA - TKANINA PLUSZOWA ŁATWOCZYSZCZĄCA" ,
                    cz: "HIMALAYA - TKANINA PLÝŠOVÁ SNADNO ČISTITELNÁ",
                    lt: "HIMALAYA - PLYŠINIS AUDINYS, LENGVAI VALOMASIS",
                    hu: "HIMALAYA - PLÜSS, KÖNNYEN TISZTÍTHATÓ SZÖVET",
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Luksusowo miękka tkanina przypominająca wełnę owczą, pochodząca w&nbsp;100% z recyklingu."
                           cz="Vysoce luxusní a měkká tkanina připomínající ovčí vlnu, vyráběná z&nbsp;100% recyklovaných materiálů."
                           lt="Prabangiai minkštas audinys primenantis avinėlio vilną, pagamintas iš &nbsp;100% perdirbtų medžiagų."
                           hu="Pompásan puha, a juhgyapjúra emlékeztető, 100%-ban újrahasznosított anyag."
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>100 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Cechy: "
                       cz="Vlastnosti:"
                       lt="Savybės:"
                       hu="Jellemzők:"
                       /></FeaturesLeft>
                        <FeatureRight><T
                           pl="Łatwe czyszczenie wodą"
                           cz="Snadno se čistí vodou"
                           lt="Lengva valyti vandeniu"
                           hu="Vízzel könnyen tisztítható"
                           /></FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Delikatne i punktowe, wodą z mydłem. Niewskazane stosowanie silnych detergentów." 
                                cz="Jemné a místní čištění vodou s mýdlem. Není doporučeno používat silné čistící prostředky."
                                lt="Švelnus ir taškinis, naudojant vandenį su muilu. Nerekomenduojama naudoti stiprių valiklių."

                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 104'} full={ASSETS_DIR+'Himalaya/himalaya-104.jpg'} />
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 303'} full={ASSETS_DIR+'Himalaya/himalaya-303.jpg'} />
                        
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 304'} full={ASSETS_DIR+'Himalaya/himalaya-304.jpg'} />
                        
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 502'} full={ASSETS_DIR+'Himalaya/himalaya-502.jpg'} />
                        
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 600'} full={ASSETS_DIR+'Himalaya/himalaya-600.jpg'} />
                        
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 806'} full={ASSETS_DIR+'Himalaya/himalaya-806.jpg'} />
                        
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 902'} full={ASSETS_DIR+'Himalaya/himalaya-902.jpg'} />
                        
                        <Item 
                            type={_t({
                                pl:'HIMALAYA', 
                                cz:'HIMALAYA', 
                                lt:'HIMALAYA', 
                                hu:'HIMALAYA', 
                            })} 
                            text={'HIMALAYA 904'} full={ASSETS_DIR+'Himalaya/himalaya-904.jpg'} />
                        
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>
                
                {/* DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl:"DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA" ,
                    cz:"DELUXE - SNADNO ČISTITELNÝ VELUR",
                    lt:"DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS",
                    hu:"DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET",
                    })} >
                   <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>100 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="Vízzel könnyen tisztítható"
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime"
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától"
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'DOVE'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-02-DOVE-600x600.JPG'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'STEEL'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-04-STEEL-600x600.jpg'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'CHARCOAL'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-05-CHARCOAL-600x600.JPG'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'PEACOCK'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-15-PEACOCK-600x600.JPG'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'AMBER'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-19-AMBER-600x600.jpg'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'WOOD'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-20-WOOD-600x600.JPG'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'SIENNA'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-22-SIENNA-600x600.jpg'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'SKY'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-27-SKY-600x600.JPG'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'PETROL'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-28-PETROL-600x600.JPG'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'INDIGO'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-29-INDIGO-600x600.jpg'} />
                        <Item type={_t({
                            pl:'DELUXE - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'DELUXE - SNADNO ČISTITELNÝ VELUR',
                            lt:'DELUXE - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'DELUXE - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} text={'SURF'} full={ASSETS_DIR+'DELUXE---TKANINA-WELUROWA-LATWOCZYSZCZACA/DELUXE-33-SURF-600x600.JPG'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>

                {/* KEYSTONE */}
                <AccordionMain label={_t({ 
                    pl: "KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA" ,
                    cz: "KEYSTONE - SNADNO ČISTITELNÁ LÁTKA",
                    lt: "KEYSTONE - LENGVAI VALOMAS AUDINYS",
                    hu: "KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET",
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas "
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Tkanina bawełniana - miękka i przyjemna w dotyku. Łatwa w utrzymaniu dzięki technologii EasyClean."
                           cz="Bavlněná tkanina - měkká a příjemná na dotek. Snadná údržba díky technologii EasyClean."
                           lt="Medvilninis audinys – minkštas ir malonus liesti. Lengva prižiūrėti dėl EasyClean technologijos."
                           hu="Puha és kellemes tapintású pamutszövet. Könnyen kezelhető az EasyClean technológiának
                           köszönhetően"
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="79% BAWEŁNA 21% POLYESTER"
                           cz="79% POLYESTER, 21% BAVLNA"
                           lt="79% MEDVILNĖ 21% POLIESTERIS"
                           hu="79% PAMUT 21% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>40 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="Vízzel könnyen tisztítható"
                            />
                    </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'SHARK'} full={ASSETS_DIR+'KEYSTONE/Shark.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'MOUSE'} full={ASSETS_DIR+'KEYSTONE/Mouse.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'SNOW'} full={ASSETS_DIR+'KEYSTONE/Snow.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'EBONY'} full={ASSETS_DIR+'KEYSTONE/Ebony.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'NIGHT'} full={ASSETS_DIR+'KEYSTONE/Night.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'NAVY'} full={ASSETS_DIR+'KEYSTONE/Navy.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'LEAF'} full={ASSETS_DIR+'KEYSTONE/Leaf.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'SUN'} full={ASSETS_DIR+'KEYSTONE/Sun.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'SPICE'} full={ASSETS_DIR+'KEYSTONE/Spice.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'POPPY'} full={ASSETS_DIR+'KEYSTONE/Poppy.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'LOLLIPOP'} full={ASSETS_DIR+'KEYSTONE/Lollipop.jpg'} />
                        <Item 
                        type={_t({
                            pl:'KEYSTONE - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'KEYSTONE - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'KEYSTONE - LENGVAI VALOMAS AUDINYS',
                            hu:'KEYSTONE - KÖNNYEN TISZTÍTHATÓ SZÖVET',
                        })} 
                        text={'VIOLET'} full={ASSETS_DIR+'KEYSTONE/Violet.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>


                {/* NAPOLI - TKANINA WELUROWA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl:"NAPOLI - TKANINA WELUROWA ŁATWOCZYSZCZĄCA" ,
                    cz:"NAPOLI - SNADNO ČISTITELNÝ VELUR",
                    lt:"NAPOLI - LENGVAI VALOMAS VELIŪRINIS AUDINYS",
                    hu:'NAPOLI - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                    })} >
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>75 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                                <T 
                                    hu="vízzel könnyen tisztítható"
                                />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                                <T 
                                    pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                    cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                    lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                    hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                                />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({
                            pl:'NAPOLI - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'NAPOLI - SNADNO ČISTITELNÝ VELUR',
                            lt:'NAPOLI - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'NAPOLI - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:"ZIELONY",cz:"Zelená", lt:"ŽALIA", hu: 'ZÖLD'})} full={ASSETS_DIR+'NAPOLI---TKANINA-WELUROWA-LATWOCZYSZCZACA/NAPOLI-12-ZIELONY.jpg'} />
                        <Item type={_t({
                            pl:'NAPOLI - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'NAPOLI - SNADNO ČISTITELNÝ VELUR',
                            lt:'NAPOLI - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'NAPOLI - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:"STALOWY",cz:"Ocelová", lt: "PLIENINĖ", hu:'SZÜRKE' })} full={ASSETS_DIR+'NAPOLI---TKANINA-WELUROWA-LATWOCZYSZCZACA/NAPOLI-13-STALOWY.jpg'} />
                        <Item type={_t({
                            pl:'NAPOLI - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'NAPOLI - SNADNO ČISTITELNÝ VELUR',
                            lt:'NAPOLI - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'NAPOLI - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:"GRANATOWY",cz:"Tmavomodrá",lt:"TAMSIAI MĖLYNA", hu: 'TENGERÉSZKÉK'})} full={ASSETS_DIR+'NAPOLI---TKANINA-WELUROWA-LATWOCZYSZCZACA/NAPOLI-16-GRANATOWY.jpg'} />
                        <Item type={_t({
                            pl:'NAPOLI - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'NAPOLI - SNADNO ČISTITELNÝ VELUR',
                            lt:'NAPOLI - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'NAPOLI - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:"SZARO-BEŻOWY",cz:"Šedobéžová",lt:"PILKA SMĖLIO", hu: 'SZÜRKE-BÉZS'})} full={ASSETS_DIR+'NAPOLI---TKANINA-WELUROWA-LATWOCZYSZCZACA/NAPOLI-19-SZARO-BEZOWY.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>

                {/* PIANO - TKANINA WELUROWA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl:"PIANO - TKANINA WELUROWA ŁATWOCZYSZCZĄCA" ,
                    cz:"PIANO - SNADNO ČISTITELNÝ VELUR",
                    lt:"PIANO - LENGVAI VALOMAS VELIŪRINIS AUDINYS",
                    hu:"PIANO - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET",
                    })} >
                   <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>60 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők: vízzel könnyen tisztítható"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható"
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({
                            pl:'PIANO - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'PIANO - SNADNO ČISTITELNÝ VELUR',
                            lt:'PIANO - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'PIANO - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:'GRANAT',cz:"Tmavomodrá", lt:"TAMSIAI MĖLYNA", hu:'TENGERÉSZKÉK'})} full={ASSETS_DIR+'PIANO---TKANINA-WELUROWA-LATWOCZYSZCZACA/PIANO-12-GRANAT.jpg'} />
                        <Item type={_t({
                            pl:'PIANO - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'PIANO - SNADNO ČISTITELNÝ VELUR',
                            lt:'PIANO - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'PIANO - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:'PUDROWY RÓŻ',cz:"Pudrově růžová", lt:"MILTELIŲ ROŽINĖ", hu:'PÚDER-RÓZSASZÍN'})} full={ASSETS_DIR+'PIANO---TKANINA-WELUROWA-LATWOCZYSZCZACA/PIANO-22-PUDROWY-ROZ.jpg'} />
                        <Item type={_t({
                            pl:'PIANO - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'PIANO - SNADNO ČISTITELNÝ VELUR',
                            lt:'PIANO - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'PIANO - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:'MUSZTARDA',cz:"Hořčičová", lt:"GARSTYČIŲ", hu:'MUSTÁRSÁRGA'})} full={ASSETS_DIR+'PIANO---TKANINA-WELUROWA-LATWOCZYSZCZACA/PIANO-23-MUSZTARDA.jpg'} />
                        <Item type={_t({
                            pl:'PIANO - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'PIANO - SNADNO ČISTITELNÝ VELUR',
                            lt:'PIANO - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                            hu:'PIANO - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={_t({ pl:'ZIELONY',cz:"Zelená",lt:"ŽALIA", hu:'ZÖLD'})} full={ASSETS_DIR+'PIANO---TKANINA-WELUROWA-LATWOCZYSZCZACA/PIANO-26-ZIELONY.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>


                {/* TIERRA */}
                <AccordionMain label={_t({ 
                    pl: "TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ" ,
                    cz: "TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ",
                    lt: "TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ",
                    hu: "TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA",
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Przyjemny w dotyku welwet dzianinowy pochodzący z recyclingu. Efekt subtelnego opalizowania."
                           cz="Na dotek příjemný recyklovaný sametový úplet."
                           lt="Malonus liesti perdirbtas trikotažinis aksomas. Subtilus vaivorykštinis efektas."
                           hu="Kellemes tapintású, újrahasznosított kötött bársony. Finoman áttetsző hatás."
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="59% RECYCLED POLYESTER, 41% POLYESTER"
                           cz="59% RECYCLED POLYESTER, 41% POLYESTER"
                           lt="59% PERDIRBTAS POLIESTERIS, 41% POLIESTERIS"
                           hu="59% ÚJRAHASZNOSÍTOTT POLYESTER, 41% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>100 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható, kisállatbarát."
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Łatwe czyszczenie wodą, przyjazne zwierzętom, utrudnione wchłanianie płynów, odporne na wirusy." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Lengva valyti vandeniu, draugiška gyvūnams, neleidžia skysčiams lengvai įsigerti, stabdo bakterijų plitimą."
                                hu="Vízzel könnyen tisztítható, kisállatbarát anyag, nehezen szívja magába a folyadékokat, ellenáll a vírusoknak"
                            />
                        </FeatureRight>
                    </Features>
                    
                    <ItemsWrapper>
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 02'} 
                            full={ASSETS_DIR+'Tierra/02.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 05'} 
                            full={ASSETS_DIR+'Tierra/05.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 11'} 
                            full={ASSETS_DIR+'Tierra/11.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 12'} 
                            full={ASSETS_DIR+'Tierra/12.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 14'} 
                            full={ASSETS_DIR+'Tierra/14.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 15'} 
                            full={ASSETS_DIR+'Tierra/15.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 16'} 
                            full={ASSETS_DIR+'Tierra/16.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 19'} 
                            full={ASSETS_DIR+'Tierra/19.jpg'} />
                        <Item 
                            type={_t({
                                pl:'TIERRA - WELUR DLA TYCH, KTÓRZY KOCHAJĄ ZIEMIĘ', 
                                cz:'TIERRA - VELOR PRO TY, KTEŘÍ MILUJÍ ZEMĚ',
                                lt:'TIERRA - VELIŪRAS MYLINTIEMS ŽEMĘ',
                                hu:'TIERRA - BÁRSONY A FÖLDBARÁT EMBEREK SZÁMÁRA',
                            })} 
                            text={'TIERRA 20'} 
                            full={ASSETS_DIR+'Tierra/20.jpg'} />
                        
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>


                {/* VERA- tkanina welurowa łatwoczyszcząca  */}
                <AccordionMain label={_t({ 
                    pl: "VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA " ,
                    cz: "VERA - SNADNO ČISTITELNÝ VELUR ",
                    lt: "VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS",
                    hu: "VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET",
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Dzianina z charakterystycznym printem w stylu vintage. Przyjemna w dotyku sprawdzi się w pokoju dziennym, sypialni i pokojach dziecięcych."
                           cz="Úplet s charakteristickým vintage potiskem. Je příjemný na dotek, vhodný do obývacích pokojů, ložnic a dětských pokojů."
                           lt="Megztas su išskirtiniu vintažiniu raštu. Malonus liesti, tinka svetainėms, miegamiesiems ir vaikų kambariams."
                           hu="Kötött anyag, jellegzetes vintage stílusú mintázattal. Kellemes tapintású, jól mutat a nappaliban, a hálószobában és a gyerekszobákban egyaránt."
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS "
                           hu="100% POLYESTER "
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>45 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Cechy:"
                           cz="Vlastnosti:"
                           lt="Savybės:"
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Łatwe czyszczenie wodą, przyjazne zwierzętom." 
                                cz="Snadno se čistí vodou, vhodný k domácím mazlíčkům."
                                lt="Lengvai valoma vandeniu, draugiška gyvūnams."
                                hu="Vízzel könnyen tisztítható, kisállatbarát."
                            />
                        </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 01'} 
                            full={ASSETS_DIR+'Vera/01.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 03'} 
                            full={ASSETS_DIR+'Vera/03.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 05'} 
                            full={ASSETS_DIR+'Vera/05.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 06'} 
                            full={ASSETS_DIR+'Vera/06.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 07'} 
                            full={ASSETS_DIR+'Vera/07.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 09'} 
                            full={ASSETS_DIR+'Vera/09.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 11'} 
                            full={ASSETS_DIR+'Vera/11.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 12'} 
                            full={ASSETS_DIR+'Vera/12.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 13'} 
                            full={ASSETS_DIR+'Vera/13.jpg'} />
                        <Item 
                            type={_t({
                                pl:'VERA - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                                cz:'VERA - SNADNO ČISTITELNÝ VELUR',
                                lt:'VERA - LENGVAI VALOMAS VELIŪRINIS AUDINYS',
                                hu:'VERA - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                        })} 
                            text={'VERA 15'} 
                            full={ASSETS_DIR+'Vera/15.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>


                {/* VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl:"VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA" ,
                    cz:"VOCAL - SNADNO ČISTITELNÝ VELUR",
                    lt:"VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS",
                    hu:"VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET",
                    })} >
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtys:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>70 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható"
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 01'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/01.jpg`} />
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 02'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/02.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 03'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/03.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 04'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/04.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 05'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/05.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 06'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/06.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 07'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/07.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 08'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/08.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 09'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/09.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 10'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/10.jpg`} />
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 11'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/11.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 12'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/12.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 13'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/13.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 14'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/14.jpg`} />
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 15'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/15.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 16'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/16.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 17'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/17.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 18'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/18.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 19'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/19.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 20'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/20.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 21'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/21.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 22'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/22.jpg`} />
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 23'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/23.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 24'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/24.jpg`} /> */}
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 25'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/25.jpg`} /> */}
                        <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 26'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/26.jpg`} />
                        {/* <Item type={_t({
                            pl:'VOCAL - TKANINA WELUROWA ŁATWOCZYSZCZĄCA', 
                            cz:'VOCAL - SNADNO ČISTITELNÝ VELUR',
                            lt:'VOCAL - LENGVAI VALOMAS VELIŪRO AUDINYS',
                            hu:'VOCAL - KÖNNYEN TISZTÍTHATÓ BÁRSONY SZÖVET',
                            })} text={'Vocal 27'} full={`${ASSETS_DIR}VOCAL-TKANINA-WELUROWA/27.jpg`} /> */}
                       </ItemsWrapper>
                       
                        <Warning />
                </AccordionMain>



                {/* SZTRUKS - TKANINA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl: "SZTRUKS - TKANINA ŁATWOCZYSZCZĄCA" ,
                    cz: "MANŠESTR - SNADNO ČISTITELNÁ LÁTKA",
                    lt: "VELVETAS - LENGVAI VALOMAS AUDINYS",
                    hu: "KORDBÁRSONY - KÖNNYEN TISZTÍTHATÓ ANYAG",
                    })} >
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>60 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható."
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({
                            pl:'SZTRUKS - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'MANŠESTR - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'VELVETAS - LENGVAI VALOMAS AUDINYS',
                            hu:'KORDBÁRSONY - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={_t({ pl:'SZARY',cz:"Šedá", lt:"PILKA", hu:'SZÜRKE'})} full={ASSETS_DIR+'SZTRUKS---TKANINA-LATWOCZYSZCZACA/SZTRUKS-3-SZARY.jpg'} />
                        <Item type={_t({
                            pl:'SZTRUKS - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'MANŠESTR - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'VELVETAS - LENGVAI VALOMAS AUDINYS',
                            hu:'KORDBÁRSONY - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={_t({ pl:'BEŻOWY',cz:"Béžová", lt:"SMĖLINĖ", hu:'BÉZS'})} full={ASSETS_DIR+'SZTRUKS---TKANINA-LATWOCZYSZCZACA/SZTRUKS-5-BEZOWY.jpg'} />
                        <Item type={_t({
                            pl:'SZTRUKS - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'MANŠESTR - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'VELVETAS - LENGVAI VALOMAS AUDINYS',
                            hu:'KORDBÁRSONY - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={_t({ pl:'TURKUSOWY',cz:"Tyrkysová", lt:"TURKIO", hu: 'TÜRKIZKÉK'})} full={ASSETS_DIR+'SZTRUKS---TKANINA-LATWOCZYSZCZACA/SZTRUKS-14-TURKUSOWY.jpg'} />
                        <Item type={_t({
                            pl:'SZTRUKS - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'MANŠESTR - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'VELVETAS - LENGVAI VALOMAS AUDINYS',
                            hu:'KORDBÁRSONY - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={_t({ pl:'GRANATOWY',cz:"Tmavomodrá", lt:"TAMSIAI MĖLYNA", hu:'TENGERÉSZKÉK'})} full={ASSETS_DIR+'SZTRUKS---TKANINA-LATWOCZYSZCZACA/SZTRUKS-15-GRANATOWY.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>


                {/* BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl: "BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA" ,
                    cz: "BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA",
                    lt: "BRAVEHEART - LENGVAI VALOMAS AUDINYS",
                    hu: "BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG",
                    })} 
                >
                   <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER"
                           cz="100% POLYESTER"
                           lt="100% POLIESTERIS"
                           hu="100% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>100 000</FeatureRight>
                    </Features>
                    
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható"
                            />
                          </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                          </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({ 
                            pl:"BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA" ,
                            cz:"BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA",
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                        })} text={'TEAL'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-03-TEAL.jpg'} />
                        {/* <Item type={_t({
                            pl: 'BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'ONYX'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-34-ONYX-600x600.jpg'} /> */}
                        <Item type={_t({
                            pl: 'BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'CHARCOAL'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-11-CHARCOAL-600x600.jpg'} />
                        <Item type={_t({
                            pl: 'BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'PIGEON'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-06-PIGEON-600x600.jpg'} />
                        <Item type={_t({
                            pl: 'BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'NAVY'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-33-NAVY-600x600.jpg'} />
                        <Item type={_t({
                            pl: 'BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'COBALT'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-32-COBALT-600x600.jpg'} />
                        <Item type={_t({
                            pl: 'BRAVEHEART - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'BRAVEHEART - SNADNO ČISTITELNÁ LÁTKA',
                            lt:'BRAVEHEART - LENGVAI VALOMAS AUDINYS',
                            hu:'BRAVEHEART - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'CHINCHILLA'} full={ASSETS_DIR+'BRAVEHEART---TKANINA-LATWOCZYSZCZACA/BRAVEHEART-15-CHINCHILLA-600x600.jpg'} />
                    </ItemsWrapper>
                    <Warning />
                </AccordionMain>

                {/* CALEIDO */}
                <AccordionMain label={_t({ 
                        pl: "CALEIDO - TKANINA NATURALNA" ,
                        cz: "CALEIDO - PŘÍRODNÍ LÁTKA",
                        lt: "CALEIDO - NATŪRALUS AUDINIS",
                        hu: "CALEIDO - TERMÉSZETES ANYAG",
                        })} 
                        >
                        <Features>
                        <FeaturesLeft><T
                        pl="Opis:"
                        cz="Popis:"
                        lt="Aprašymas:"
                        hu="Leírás:"
                        />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T
                            pl="Klasyczne połączenie lnu i bawełny, tkanina miękka i przyjemna w dotyku. Produkowana w jednej najstarszych przędzalni we Włoszech. "
                            cz="Klasická kombinace lnu a bavlny, látka je měkká a příjemná na dotek. Vyrábí se v jedné z nejstarších přádelen v Itálii."
                            lt="Klasikinis lino ir medvilnės derinys, audinys švelnus ir malonus liesti. Pagaminta vienoje iš seniausių verpimo gamyklų Italijoje."
                            hu="A vászon és a pamut klasszikus kombinációja, az anyag puha és kellemes tapintású. Olaszország egyik legrégebbi fonodájában készül."
                            />
                            </FeatureRight>
                    </Features>
                        <Features>
                        <FeaturesLeft><T
                        pl="Skład:"
                        cz="Složení:"
                        lt="Sudėtis:"
                        hu="Összetétel:"
                        />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T
                            cz="63% BAVLNA 37% LEN"
                            pl="63% BAWEŁNA 37% LEN"
                            lt="63% MEDVILNĖ 37% LINAS"
                            hu="63% PAMUT 37% LENVÁSZON"
                            />
                            </FeatureRight>
                    </Features>
                        <Features>
                            <FeaturesLeft><T
                        pl="Ścieralność:"
                        cz="Odolnost proti oděru:"
                        lt="Atsparumas trinčiai:"
                        hu="Kopásállóság:"
                        /></FeaturesLeft>
                            <FeatureRight>32 500</FeatureRight>
                        </Features>
                        {/* <Features>
                            <FeaturesLeft>
                            <T
                            hu="Jellemzők"
                            />
                            </FeaturesLeft>
                            <FeatureRight>
                                <T 
                                    hu="Vízzel könnyen tisztítható, kisállatbarát"
                                />
                            </FeatureRight>
                        </Features> */}
                        <Features>
                            <FeaturesLeft>
                            <T
                            pl="Czyszczenie zabrudzeń:"
                            cz="Odstraňování nečistot:"
                            lt="Nešvarumų valymas:"
                            hu="Foltok tisztítása:"
                            />
                            </FeaturesLeft>
                            <FeatureRight>
                                <T 
                                    pl="Ze względu na naturalne barwienie czyszczenie wyłącznie wodą. Tkaniny nie należy czyścić punktowo" 
                                    cz="Jemné čištění pouze vodou na větší ploše"
                                    lt="Dėl natūralaus audinio dažymo, dėmes valykite tik vandeniu. Audinio negalima valyti taškiniu būdu"
                                    hu="A természetes festés miatt csak vízzel tisztítható. Az anyagot nem szabad pontszerűen tisztítani."
                                />
                            </FeatureRight>
                        </Features>
                        <ItemsWrapper>
                            <Item type={_t({
                                pl: 'CALEIDO - TKANINA NATURALNA', 
                                cz:'CALEIDO - PŘÍRODNÍ LÁTKA', 
                                lt:'CALEIDO - NATŪRALUS AUDINIS',
                                hu:'CALEIDO - TERMÉSZETES ANYAG',
                                })} text={_t({ pl:"SZARO-BEŻOWY",cz:"Šedobéžová", lt:"PILKAI-SMĖLINĖ", hu:'SZÜRKE-BÉZS'})}  full={ASSETS_DIR+'CALEIDO---TKANINA-NATURALNA/SZARO---BEZOWE-2994.jpg'} />
                            <Item type={_t({
                                pl: 'CALEIDO - TKANINA NATURALNA', 
                                cz:'CALEIDO - PŘÍRODNÍ LÁTKA', 
                                lt:'CALEIDO - NATŪRALUS AUDINIS',
                                hu:'CALEIDO - TERMÉSZETES ANYAG',
                                })} text={_t({ pl:'PUDROWY NIEBIESKI',cz:"PUDROVĚ MODRÁ", lt:"PUDRINĖ MĖLYNA", hu:'PÚDERKÉK'})} full={ASSETS_DIR+'CALEIDO---TKANINA-NATURALNA/CALEIDO-PUBROWY-NIEBIESKI-10993.jpg'} />
                            <Item type={_t({
                                pl: 'CALEIDO - TKANINA NATURALNA', 
                                cz:'CALEIDO - PŘÍRODNÍ LÁTKA', 
                                lt:'CALEIDO - NATŪRALUS AUDINIS',
                                hu:'CALEIDO - TERMÉSZETES ANYAG',
                                })} text={_t({ pl:'ANTRACYT',cz:"Antracitová", lt:"ANTRACITO", hu:'ANTRACIT-SZÜRKE'})} full={ASSETS_DIR+'CALEIDO---TKANINA-NATURALNA/CALEIDO-ANTRACYT-2934.jpg'} />
                            <Item type={_t({
                                pl: 'CALEIDO - TKANINA NATURALNA', 
                                cz:'CALEIDO - PŘÍRODNÍ LÁTKA', 
                                lt:'CALEIDO - NATŪRALUS AUDINIS',
                                hu:'CALEIDO - TERMÉSZETES ANYAG',
                                })} text={_t({ pl:'MIĘTOWY',cz:"Mátová", lt:"MĖTINĖ", hu: 'MENTA'})} full={ASSETS_DIR+'CALEIDO---TKANINA-NATURALNA/2248-MIETOWY.jpg'} />
                            <Item type={_t({
                                pl: 'CALEIDO - TKANINA NATURALNA', 
                                cz:'CALEIDO - PŘÍRODNÍ LÁTKA', 
                                lt:'CALEIDO - NATŪRALUS AUDINIS',
                                hu:'CALEIDO - TERMÉSZETES ANYAG',
                                })} text={_t({ pl:'JASNO SZARY',cz:"Světlešedá", lt:"ŠVIESIAI PILKA", hu: 'VILÁGOSSZÜRKE'})} full={ASSETS_DIR+'CALEIDO---TKANINA-NATURALNA/CALEIDO-JASNOSZAY-1497.jpg'} />
                        </ItemsWrapper>
                        
                            <Warning />
                </AccordionMain>
                
                
                {/* COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA */}
                <AccordionMain label={_t({ 
                    pl: "COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA" ,
                    cz: "COLOURWASH - SNADNO ČISTITELNÁ LÁTKA",
                    lt: "COLOURWASH - LENGVAI VALOMAS AUDINYS",
                    hu: "COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG",
                    })} >
                <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="73% POLYESTER, 27% BAWEŁNA"
                           cz="73% POLYESTER, 27% BAVLNA"
                           lt="73% POLIESTERIS, 27% MEDVILNĖ"
                           hu="73% POLYESTER, 27% PAMUT"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>100 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható"
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'NAVY'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-37-NAVY-600x600.jpg'} />
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'AUBURN'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-30-AUBURN-600x600.jpg'} />
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'BOTTLE'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-22-BOTTLE-600x600.jpg'} />
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'SAND'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-11-SAND-600x600.jpg'} />
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'CHARCOAL'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-07-CHARCOAL-600x600.jpg'} />
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'SHARK'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-04-SHARK-600x600.jpg'} />
                        <Item type={_t({
                            pl:'COLOURWASH - TKANINA ŁATWOCZYSZCZĄCA', 
                            cz:'COLOURWASH - SNADNO ČISTITELNÁ LÁTKA', 
                            lt:'COLOURWASH - LENGVAI VALOMAS AUDINYS',
                            hu:'COULOURWASH - KÖNNYEN TISZTÍTHATÓ ANYAG',
                            })} text={'SHADOW'} full={ASSETS_DIR+'COLOURWASH---TKANINA-LATWOCZYSZCZACA/COLOURWASH-03-SHADOW-600x600.jpg'} />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>
                
                
                {/* COMFORMY - TKANINY DEDYKOWANE */}
                <AccordionMain label={_t({ 
                    pl: "COMFORMY - TKANINY DEDYKOWANE " ,
                    cz: "COMFORMY - SPECIÁLNĚ URČENÉ TKANINY",
                    lt: "COMFORMY - SPECIALIAI SKIRTI AUDINIAI",
                    hu: "COMFORMY - SPECIÁLIS SZÖVETEK",
                    })} >
                  <Description>
                    <T 
                    pl={`Tkaniny dedykowane linii mebli <a href="https://scandicsofa.pl/comformy" target="_blank">Comformy</a> o wysokiej odporności na zabrudzenia. 
                      Step - wytrzymała tkanina z ognioodpornego materiału. Era - subtelna tkanina ze splotem w jodełkę. Venus Velvet - przyjemny w dotyku welur. 
                      Contention, Dandy & Ultima Luna - tkaniny strukturalne. `}
                    cz={`Tkaniny určené pro řadu nábytku Comformy s vysokou odolností proti znečištění. 
                      Step - odolná tkanina z hořlavého materiálu. 
                      Era - jemná tkanina s jedlovým vzorem. 
                      Venus Velvet - příjemný sametový materiál. Contention, Dandy & Ultima Luna - strukturované tkaniny.`}
                    lt={`Tkaninos skirtos Comformy baldų linijai su dideliu atsparumu dėmių užteršimams. 
                      Step - patvarus audinys iš atsparaus ugniai materialo. 
                      Era - subtilus medvilnės audinys su gegniu eglutei. 
                      Venus Velvet - malonus lietiškas audinys. 
                      Contention, Dandy ir Ultima Luna - tekstūriniai audiniai.`}
                      hu={`A Comformy bútorcsaládhoz tartozó, nagy foltállósággal rendelkező szövetek. Step - strapabíró szövet tűzálló felülettel. Era - finom halszálkás szövésű anyag. Venus Velvet - kellemes tapintású bársony. Contention, Dandy & Ultima Luna - strukturált szövetek.`}
                    />
                    
                  </Description>
                  <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% POLYESTER, DANDY RAFFIA: 81% POLYESTER, 19% BAWEŁNA"
                           cz="100% POLYESTER, DANDY RAFFIA: 81% POLYESTER, 19% BAVLNA"
                           lt="100% POLIESTERIS, DANDY RAFFIA: 81% POLIESTERIS, 19% MEDVILNĖ"
                           hu="100% POLYESTER, RAFFIA: 81% POLYESTER, 19% PAMUT"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>
                          <T 
                          pl="100 000, Contention - 30 000 " 
                          hu="100 000, kivéve a Contention: 30 000" 
                          />
                           
                          </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Cechy:"
                           cz="Vlastnosti:"
                           lt="Savybės:"
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Łatwe czyszczenie wodą" 
                                cz="Snadno se čistí vodou"
                                lt="Lengva valyti vandeniu"
                                hu="Vízzel könnyen tisztítható"
                            />
                        </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Anteprima Luna'} full={ASSETS_DIR+'Comformy/AnteprimaLuna.png'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Contention Ink'} full={ASSETS_DIR+'Comformy/ContentionInk.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Contention Sand'} full={ASSETS_DIR+'Comformy/ContentionSand.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Dandy Raffia'} full={ASSETS_DIR+'Comformy/DandyRaffia.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Era Calendar'} full={ASSETS_DIR+'Comformy/EraCalendar.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Era Quota'} full={ASSETS_DIR+'Comformy/EraCamira.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Era Occurence'} full={ASSETS_DIR+'Comformy/EraOccurence.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 60004'} full={ASSETS_DIR+'Comformy/Step60004.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 60011'} full={ASSETS_DIR+'Comformy/Step60011.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 61149'} full={ASSETS_DIR+'Comformy/Step61149.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 62057'} full={ASSETS_DIR+'Comformy/Step62057.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 63075'} full={ASSETS_DIR+'Comformy/Step63075.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 65023'} full={ASSETS_DIR+'Comformy/Step65023.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 65093'} full={ASSETS_DIR+'Comformy/Step65093.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 66152'} full={ASSETS_DIR+'Comformy/Step66152.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 68121'} full={ASSETS_DIR+'Comformy/Step68121.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Step 68161'} full={ASSETS_DIR+'Comformy/Step68161.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Venus Velvet 2929'} full={ASSETS_DIR+'Comformy/VenusVelvet2929.jpg'} 
                        />
                        <Item type={_t({
                          pl:'COMFORMY - TKANINY DEDYKOWANE', 
                          cz:'COMFORMY - SNADNO ČISTITELNÁ LÁTKA', 
                          lt:'COMFORMY - LENGVAI VALOMAS AUDINYS',
                          hu:'COMFORMY - SPECIÁLIS SZÖVETEK',
                        })} text={'Venus Velvet 2934'} full={ASSETS_DIR+'Comformy/VenusVelvet2934.jpg'} 
                        />
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain>
                
                {/* LEMMA- tkanina szenilowa łatwoczyszcząca   */}
                {/* <AccordionMain label={_t({ 
                    pl: "LEMMA - TKANINA SZENILOWA ŁATWOCZYSZCZĄCA " ,
                    cz: "LEMMA - ŽINYLKOVÁ LÁTKA SE SNADNOU ÚDRŽBOU ",
                    lt: "LEMMA - ŠENILINIS AUDINYS, KURĮ LENGVA VALYTI"
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Tkanina szenilowa niezwykle miękka i przyjemna w dotyku. Posiada wysoką gramaturę. Ł czyszczenie oraz utrudnione wchłanianie rozlanych substancji zagwarantują komfort użytkowania, czysty i atrakcyjny mebel przez lata."
                           cz="Žinylková tkanina je extrémně měkká a příjemná na dotek. Má vysokou gramáž. Snadné čištění a obtížné vstřebávání rozlitých látek zajistí komfortní používání a čistý a atraktivní kus nábytku po mnoho let."
                           lt=" Šenilinis audinys yra itin minkštas ir malonus liesti. Jis yra pūstas ir pūkuotas. Lengvas valymas ir atsparumas skysčiams užtikrins patogų, švarų ir patrauklų baldą ilgus metus."
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="66% POLYAMIDE, 34% POLYESTER"
                           cz="66% POLYAMIDE, 34% POLYESTER"
                           lt="66% POLIAMIDAS, 34% POLIESTERIS"
                           hu="66% POLIAMIDAS, 34% POLIESTERIS"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>45 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Cechy:"
                           cz="Vlastnosti:"
                           lt="Savybės:"
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Łatwe czyszczenie wodą, utrudnione wchłanianie płynów" 
                                cz="Snadno se čistí vodou, jen málo absorbuje tekutiny"
                                lt=" Lengvas valymas vandeniu, atsparumas skysčių įsigėrimui "
                            />
                        </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                    
                        <Item 
                            type={_t({
                                pl:'LEMMA - TKANINA SZENILOWA ŁATWOCZYSZCZĄCA', 
                                cz:'LEMMA - ŽINYLKOVÁ LÁTKA SE SNADNOU ÚDRŽBOU',
                                lt:'LEMMA - ŠENILINIS AUDINYS, KURĮ LENGVA VALYTI'})} 
                            text={'LEMMA 01'} 
                            full={ASSETS_DIR+'Lemma/01.jpg'} />    
                        <Item 
                            type={_t({
                                pl:'LEMMA - TKANINA SZENILOWA ŁATWOCZYSZCZĄCA', 
                                cz:'LEMMA - ŽINYLKOVÁ LÁTKA SE SNADNOU ÚDRŽBOU',
                                lt:'LEMMA - ŠENILINIS AUDINYS, KURĮ LENGVA VALYTI'})} 
                            text={'LEMMA 05'} 
                            full={ASSETS_DIR+'Lemma/05.jpg'} />    
                        <Item 
                            type={_t({
                                pl:'LEMMA - TKANINA SZENILOWA ŁATWOCZYSZCZĄCA', 
                                cz:'LEMMA - ŽINYLKOVÁ LÁTKA SE SNADNOU ÚDRŽBOU',
                                lt:'LEMMA - ŠENILINIS AUDINYS, KURĮ LENGVA VALYTI'})} 
                            text={'LEMMA 06'} 
                            full={ASSETS_DIR+'Lemma/06.jpg'} />    
                        <Item 
                            type={_t({
                                pl:'LEMMA - TKANINA SZENILOWA ŁATWOCZYSZCZĄCA', 
                                cz:'LEMMA - ŽINYLKOVÁ LÁTKA SE SNADNOU ÚDRŽBOU',
                                lt:'LEMMA - ŠENILINIS AUDINYS, KURĮ LENGVA VALYTI'})} 
                            text={'LEMMA 07'} 
                            full={ASSETS_DIR+'Lemma/07.jpg'} />    
                        <Item 
                            type={_t({
                                pl:'LEMMA - TKANINA SZENILOWA ŁATWOCZYSZCZĄCA', 
                                cz:'LEMMA - ŽINYLKOVÁ LÁTKA SE SNADNOU ÚDRŽBOU',
                                lt:'LEMMA - ŠENILINIS AUDINYS, KURĮ LENGVA VALYTI'})} 
                            text={'LEMMA 08'} 
                            full={ASSETS_DIR+'Lemma/08.jpg'} />    
                        
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain> */}

                {/* VAMOS - tkanina sztruksowa łatwozmywalna   */}
                {/* <AccordionMain label={_t({ 
                    pl: "VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA" ,
                    cz: "VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA",
                    lt: "VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS"
                })}>
                    <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Przyjemna w dotyku tkanina szenilowa z efektem charakterystycznego sztruksu. Certyfikat Oeko Tex daje nam pewność, że tkanina jest pozbawiona alergenów oraz całkowicie bezpieczna dla życia i zdrowia człowieka."
                           cz="Na dotek příjemná žinylková látka s charakteristickým manšestrovým efektem. Certifikát Oeko Tex nám dává jistotu, že látka je bez alergenů a zcela zdravotně nezávadná."
                           lt="Malonus liesti šenilinis audinys su išskirtiniu velveto efektu. Oeko Tex sertifikatas užtikrina, kad audinys neturi alergenų ir nekenkia žmogaus ir sveikatai."
                           />
                        </FeatureRight>
                   </Features>
                    <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="47% POLYESTER, 45% POLYAMIDE, 8% WISKOZA"
                           cz="47% POLYESTER, 45% POLYAMIDE, 8% WISKOZA"
                           lt="47% POLIESTERIS, 45% POLIAMIDAS, 8% VISKOZĖ"
                           hu="47% POLIESTERIS, 45% POLIAMIDAS, 8% VISKOZĖ"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>35 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Cechy:"
                           cz="Vlastnosti:"
                           lt="Savybės:"
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Łatwe czyszczenie wodą, utrudnione wchłanianie płynów" 
                                cz="Snadno se čistí vodou, jen málo absorbuje tekutiny"
                                lt="Lengvas valymas vandeniu, atsparumas skysčių įsigėrimui"
                            />
                        </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime."
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                        </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 02'} 
                            full={ASSETS_DIR+'Vamos/02.jpg'} />  
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 05'} 
                            full={ASSETS_DIR+'Vamos/05.jpg'} />  
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 07'} 
                            full={ASSETS_DIR+'Vamos/07.jpg'} />  
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 08'} 
                            full={ASSETS_DIR+'Vamos/08.jpg'} />  
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 10'} 
                            full={ASSETS_DIR+'Vamos/10.jpg'} />  
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 11'} 
                            full={ASSETS_DIR+'Vamos/11.jpg'} />  
                        <Item 
                            type={_t({
                                pl:'VAMOS - TKANINA SZTRUKSOWA ŁATWOZMYWALNA', 
                                cz:'VAMOS - SNADNO OMYVATELNÁ MANŠESTROVÁ LÁTKA',
                                lt:'VAMOS - LENGVAI VALOMAS VELVETINIS AUDINYS'
                            })} 
                            text={'VAMOS 12'} 
                            full={ASSETS_DIR+'Vamos/12.jpg'} />  
                    </ItemsWrapper>
                    
                        <Warning />
                </AccordionMain> */}
                
                {/* WELUR - TKANINA NATURALNA */}
                <AccordionMain label={_t({ 
                    cz:"VELUR - PŘÍRODNÍ LÁTKA" ,
                    pl:"WELUR - TKANINA NATURALNA",
                    lt:"VELIŪRAS - NATŪRALUS AUDINYS",
                    hu:"BÁRSONY – TERMÉSZETES ANYAG",
                    })} >
                  <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Szlachetny welur z włoskich przędzalni. Naturalny skład i barwienie zapewniają wyjątkowy kolor. Skierowana do tych, którzy cenią piękno i luksus we wnętrzach."
                           cz="Jemný velur z italských přádelen. Přírodní složení a barvení zajišťuje jedinečnou barvu. Určeno pro ty, kteří oceňují krásu a luxus v interiérech."
                           lt="Puikus veliūras iš Italijos verpimo fabrikų. Natūrali sudėtis ir dažiklis suteikia unikalią spalvą. Skirta vertinantiems grožį ir prabangų interjerą. "
                           hu="Finom bársony olasz fonodákból. A természetes Összetétel és a festés kivételes színvilágot biztosít. Azoknak szól, akik értékelik a szépséget és a luxust a belső helyiségekben. "
                           />
                           </FeatureRight>
                   </Features>
                  <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% BAWEŁNA"
                           cz="100% BAVLNA"
                           lt="100% MEDVILNĖ"
                           hu="100% PAMUT"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>35 000</FeatureRight>
                    </Features>
                    {/* <Features>
                        <FeaturesLeft>
                        <T
                           hu="Jellemzők:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                hu="vízzel könnyen tisztítható"
                            />
                        </FeatureRight>
                    </Features> */}
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Delikatne czyszczenie wyłącznie wodą." 
                                cz="Jemné čištění pouze vodou."
                                lt="Švelniai valykite naudojant tik vandenį."
                                hu="Kíméletes tisztítás csak vízzel."
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'CZARNY',cz:"Černá",lt:"JUODA", hu:'FEKETE'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-148-CZARNY.jpg'} />
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'BORDO',cz:"Bordová",lt:"BORDO", hu:'BORDÓ'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-313-BORDO.jpg'} />
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'OLIWKA',cz:"Olivová",lt:"ALYVUOGIŲ", hu:'OLÍVAZÖLD'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-324-OLIWKA.jpg'} />
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'ZIELONY',cz:"Zelená", lt:"ŽALIA",lt:"ŽALIA", hu:'ZÖLD'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-327-ZIELONY.jpg'} />
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'JASNOSZARY',cz:"Světlešedá",lt:"ŠVIESIAI PILKA", hu:'VILÁGOS-SZÜRKE'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-336-JASNOSZARY.jpg'} />
                        {/* <Item type={'WELUR - TKANINA NATURALNA'} text={'TURKUSOWY'} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-1547-TURKUSOWY.jpg'} /> */}
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'GRANATOWY',cz:"Tmavomodrá",lt:"TAMSIAI MĖLYNA", hu:'TENGERÉSZKÉK'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-1555-GRANATOWY.jpg'} />
                        {/* <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'POPIEL',cz:"Popelavá",lt:"PELENŲ"})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-1602-POPIEL.jpg'} /> */}
                        {/* <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'CIEMNOZIELONY',cz:"Tmavě zelená",lt:"TAMSIAI ŽALIA"})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-1667-CIEMNOZIELONY.jpg'} /> */}
                        <Item type={_t({ 
                            pl:'WELUR - TKANINA NATURALNA', 
                            cz:'VELUR - PŘÍRODNÍ LÁTKA',
                            lt:'VELIŪRAS - NATŪRALUS AUDINYS',
                            hu:'VELÚR',
                            })} text={_t({ pl:'MUSZTARDA',cz:"Hořčičová",lt:"GARSTYČIŲ", hu:'MUSTÁRSÁRGA'})} full={ASSETS_DIR+'WELUR-TKANINA-NATURALNA/WELUR-2219-MUSZTARDA.jpg'} />
                    </ItemsWrapper>
                    
                    <Warning />
                </AccordionMain>

                {/* CAPSULE - MIĘKKA TKANINA Z RECYKLINGU */}
                <AccordionMain label={_t({ 
                    cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                    pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                    lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                    hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                    })} >
                  <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="Miękka i luksusowa w dotyku tkanina o koszykowym splocie. Doskonale podkreśli obłe kształty mebli "
                           cz="Jemná a luxusní látka na dotek s košíkovou vazbou. Dokonale zdůrazní zaoblené tvary nábytku."
                           lt="Minkštas ir prabangus liesti audinys su pintinėmis struktūromis. Puikiai pabrėš baldų apvalius formas."
                           hu="Puha és fényűző tapintású, kosárfonású szövet. Tökéletesen kiemeli a bútorok lekerekített formáit."
                           />
                           </FeatureRight>
                   </Features>
                  <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="100% RECYCLED POLYESTER"
                           cz="100% RECYCLED POLYESTER"
                           lt="100% PERDIRBTAS POLIESTERIS"
                           hu="100% ÚJRAHASZNOSÍTOTT POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>40 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Cechy:"
                       cz="Vlastnosti:"
                       lt="Savybės:"
                       hu="Jellemzők:"
                       /></FeaturesLeft>
                        <FeatureRight>
                          <T 
                          pl="Łatwe czyszczenie wodą"
                          cz="Snadno se čistí vodou"
                          lt="Lengva valyti vandeniu"
                          hu="Vízzel könnyen tisztítható"
                          />
                        </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime"
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                        
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Ash',cz:"Ash",lt:"Ash"})} full={ASSETS_DIR+'Capsule/Ash.jpg'} />
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Cashew',cz:"Cashew",lt:"Cashew"})} full={ASSETS_DIR+'Capsule/Cashew.jpg'} />
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Cashmere',cz:"Cashmere",lt:"Cashmere"})} full={ASSETS_DIR+'Capsule/Cashmere.jpg'} />
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Funghi',cz:"Funghi",lt:"Funghi"})} full={ASSETS_DIR+'Capsule/Funghi.jpg'} />
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Lake',cz:"Lake",lt:"Lake"})} full={ASSETS_DIR+'Capsule/Lake.jpg'} />
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Aqua',cz:"Aqua",lt:"Aqua"})} full={ASSETS_DIR+'Capsule/Aqua.jpg'} />
                        <Item type={_t({ 
                            cz:"CAPSULE - JEMNÁ LÁTKA Z RECYKLACE" ,
                            pl:"CAPSULE - MIĘKKA TKANINA Z RECYKLINGU",
                            lt:"CAPSULE - MINKŠTAS PERDIRBIMO AUDINYS",
                            hu:"CAPSULE - PUHA ÚJRAHASZNOSÍTOTT SZÖVET",
                          })} text={_t({ pl:'Sesame',cz:"Sesame",lt:"Sesame"})} full={ASSETS_DIR+'Capsule/Sesame.jpg'} />
                            
                    </ItemsWrapper>
                    
                    <Warning />
                </AccordionMain>

                {/* ZENA - TKANINA Z WYRAZISTYM SPLOTEM */}
                <AccordionMain label={_t({ 
                    cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                    pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                    lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                    hu:"ZENA - TEXTURÁLT SZÖVET",
                    })} >
                  <Features>
                       <FeaturesLeft><T
                       pl="Opis:"
                       cz="Popis:"
                       lt="Aprašymas:"
                       hu="Leírás:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl='Wyrazista tkanina z różnymi wielkościami przędz i starannie dobranymi kolorami, umożliwiająca spójne aranżacje. Kolekcja zawiera kontrasty kolorystyczne (czarno-kremowe "pieprz i sól") oraz zestawienia "ton w ton".  '
                           cz="Výrazná tkanina s různými velikostmi přízí a pečlivě vybranými barvami, umožňující konzistentní aranžmá. Kolekce obsahuje barevné kontrasty (černá-smetanová „koření a sůl“) a kombinace tón v tónu."
                           lt='Išskirtinis audinys su skirtingais siūlais ir atidžiai derinamais spalvomis, leidžiantis suderintas kompozicijas. Kolekcija apima spalvinius kontrastus (juodo-baltos spalvos "pipiras ir druska") bei tos pačios spalvos derinius ("tonas į toną")'
                           hu='Leírás: A texturált szövetek készítésekor különböző fonalméretekkel és gondosan kiválasztott színekkel dolgozunk, amelyek együtt harmonikus összhatást eredményeznek. A kollekcióban színkontrasztok (fekete és krémszínű "bors és só") és tónusos kombinációk is szerepelnek.'
                           />
                           </FeatureRight>
                   </Features>
                  <Features>
                       <FeaturesLeft><T
                       pl="Skład:"
                       cz="Složení:"
                       lt="Sudėtis:"
                       hu="Összetétel:"
                       />
                       </FeaturesLeft>
                       <FeatureRight>
                           <T
                           pl="54% ACYRLIC, 46% POLYESTER"
                           cz="54% ACYRLIC, 46% POLYESTER"
                           lt="54% AKRILAS, 46% POLIESTERIS"
                           hu="54% AKRIL, 46% POLYESTER"
                           />
                           </FeatureRight>
                   </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Ścieralność:"
                       cz="Odolnost proti oděru:"
                       lt="Atsparumas trinčiai:"
                       hu="Kopásállóság:"
                       /></FeaturesLeft>
                        <FeatureRight>40 000</FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft><T
                       pl="Cechy:"
                       cz="Vlastnosti:"
                       lt="Savybės:"
                       hu="Jellemzők:"
                       /></FeaturesLeft>
                        <FeatureRight>
                          <T 
                          pl="Łatwe czyszczenie wodą"
                          cz="Snadno se čistí vodou"
                          lt="Lengva valyti vandeniu"
                          hu="Vízzel könnyen tisztítható."
                          />
                        </FeatureRight>
                    </Features>
                    <Features>
                        <FeaturesLeft>
                        <T
                           pl="Czyszczenie zabrudzeń:"
                           cz="Odstraňování nečistot:"
                           lt="Nešvarumų valymas:"
                           hu="Foltok tisztítása:"
                           />
                        </FeaturesLeft>
                        <FeatureRight>
                            <T 
                                pl="Zastosuj wodę na plamę, wytrzyj delikatną ściereczką i pozostaw do samoczynnego wyschnięcia." 
                                cz="Polijte skvrnu vodou, otřete měkkým hadříkem a nechte ji zaschnout."
                                lt="Suvilgykite dėmę vandeniu, nuvalykite minkštu skudurėliu ir palikite, kad išdžiūtų savaime"
                                hu="A foltot vízzel itassa fel, törölje át puha ruhával, és hagyja megszáradni magától."
                            />
                    </FeatureRight>
                    </Features>
                    <ItemsWrapper>
                      {/* Zena: Oyster, Sky, Marble, Dice, Boudoir i Capri (6 kolorków)  */}

                        <Item type={_t({ 
                            cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                            pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                            lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                            hu:"ZENA - TEXTURÁLT SZÖVET",
                          })} text={_t({ pl:'Boudoir',cz:"Boudoir",lt:"Boudoir"})} full={ASSETS_DIR+'Zena/Boudoir.jpg'} />
                        <Item type={_t({ 
                            cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                            pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                            lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                            hu:"ZENA - TEXTURÁLT SZÖVET",
                          })} text={_t({ pl:'Capri',cz:"Capri",lt:"Capri"})} full={ASSETS_DIR+'Zena/Capri.jpg'} />
                        <Item type={_t({ 
                            cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                            pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                            lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                            hu:"ZENA - TEXTURÁLT SZÖVET",
                          })} text={_t({ pl:'Dice',cz:"Dice",lt:"Dice"})} full={ASSETS_DIR+'Zena/Dice.jpg'} />
                        <Item type={_t({ 
                            cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                            pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                            lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                            hu:"ZENA - TEXTURÁLT SZÖVET",
                          })} text={_t({ pl:'Marble',cz:"Marble",lt:"Marble"})} full={ASSETS_DIR+'Zena/Marble.jpg'} />
                        <Item type={_t({ 
                            cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                            pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                            lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                            hu:"ZENA - TEXTURÁLT SZÖVET",
                          })} text={_t({ pl:'Oyster',cz:"Oyster",lt:"Oyster"})} full={ASSETS_DIR+'Zena/Oyster.jpg'} />
                        <Item type={_t({ 
                            cz:"ZENA - LÁTKA S VÝRAZNÝM TKANÍM" ,
                            pl:"ZENA - TKANINA Z WYRAZISTYM SPLOTEM",
                            lt:"ZENA - AUDINYS SU IŠSKIRTINE AUDINIU",
                            hu:"ZENA - TEXTURÁLT SZÖVET",
                          })} text={_t({ pl:'Sky',cz:"Sky",lt:"Sky"})} full={ASSETS_DIR+'Zena/Sky.jpg'} />
                          
                            
                    </ItemsWrapper>
                    
                    <Warning />
                </AccordionMain>

            </Accordion>

            <ModalFooter>
                <FooterButton onClick={confirmStatement} value={'Super Value'}>
                    <T
                    pl="Zamów darmowy próbnik"
                    cz="Objednejte si vzorník zdarma"
                    lt="Užsisakykite nemokamą pavyzdį"
                    hu="Rendeljen ingyenes szövetmintát"
                    />
                    </FooterButton>
                <FooterButton onClick={props.onClose} value={'Super Value'} className={'exit'}>
                    <T
                    pl="Zamknij"
                    cz="Zavřít"
                    lt="Close"
                    hu="Close"
                    />
                    </FooterButton>
            </ModalFooter>

        </Body>
    )
}
export {ModalBody}
